/* Common CSS */
@font-face {
  font-family: "Overpass-Regular";
  /* src: url(../../../assets/fonts/Overpass-Regular.ttf) format("truetype"); */
  src: url(./assets/fonts/Overpass-Regular.ttf) format("truetype");
}

body {
  background: #0d0d0d;
  background-repeat: no-repeat;
  height: 100vh;
  font-family: "Overpass-Regular" !important;
  position: relative;
  overflow-y: hidden;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
  margin: 0;
  padding: 0;
  line-height: normal;
}


.app-logo {
  width: 100%;
}

/* Extra CSS */

.splash-wrapper {
  text-align: center;
}

.splash-wrapper p {
  margin: 20px 0 60px;
  font-size: 18px;
  color: #f5f5f5;
}


/* ---------ROULY----------- */
:root {
  --primary: #0d0d0d;
  --secondary: #000;
  --ternary: #fff;
  --quaternary: #f5f5f5;
  --loader: #16deb5;
}

.app-container {
  /* background: var(--primary); */
  /* background: linear-gradient(45deg, #0b252e, #00142f) no-repeat; */
  background-repeat: no-repeat;
  height: 100vh;
  font-family: "Overpass-Regular" !important;
  position: relative;
  overflow-y: hidden;
}

.wallet-common-button {
  max-width: 300px;
  width: 100%;
  height: 50px;
  border: 0;
  outline: 0;
  font-size: 18px;
  border-radius: 30px;
  transition: 0.3s all;
  color: var(--ternary);
  font-weight: 550;
  letter-spacing: 0.5px;
  background: linear-gradient(45deg, #21b3de, #691ffb);
}


/* ----------------start splash screen--------- */

.splash-wrapper,
.wallet-wrapper,
.create-wrapper,
.import-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 0 20px;
}

.splash-wrapper .app-logo {
  width: 330px;
}
.splash-wrapper .blockchain p {
  margin: 0 0 15px 0;
  color: #f5f5f5;
  font-weight: 400;
}
.blockchain .wallet-common-button {
  margin-bottom: 15px !important;
}
.splash-wrapper .blockchain .blockchainMono {
  height: 22px;
  margin-right: 5px;
}
/* .splash-wrapper button {
  margin-bottom: 110px;
  position: absolute; 
  bottom: 0;
} */
.splash-wrapper .blockchain .splashNote {
  position: absolute;
  margin: 0;
  font-size: 12px;
  color: #555!important;
  line-height: normal;
  bottom: 10px;
  left: 0;
  padding: 0 20px;
}


.import-wrapper .import-content {
  margin-bottom: 20px;
}

.import-wrapper .form-control {
  color: var(--quaternary);
  background-color: transparent;
  border: 1px solid rgb(255 255 255 / 20%) !important;
  margin-bottom: 20px;
  padding: 10px;
}

::placeholder {
  color: rgb(255 255 255 / 20%) !important;
  font-size: 16px !important;
  font-weight: 100 !important;
}

.import-wrapper .form-control:focus {
  box-shadow: none !important;
  border: 1px solid var(--quaternary) !important;
}

.import-wrapper textarea {
  height: auto;
}

.import-wrapper .import-btn-wrapper {
  justify-content: space-between;
  width: 100%;
}

.import-wrapper .import-btn-wrapper .wallet-common-button {
  max-width: 180px;
}

.import-wrapper .borderBtn {
  color: var(--ternary);
  background: transparent;
  border: 1px solid var(--ternary);
}


.dashboard-activities-wrapper {
  overflow-y: scroll;
  height: 100vh;
  padding-bottom: 450px;
  /* max-height: 280px; */
}

/* @media (min-height:800px) {
    .dashboard-activities-wrapper {
        overflow-y: scroll;
        height: 100vh;
        max-height: 425px !important;
    }

} */
/* -----Bottom BG----------------- */
.dashboard-bottom-bg {
  content: "";
  position: absolute;
  width: 100%;
  height: 150px;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, var(--secondary));
}

.dashboard-activities-wrapper::-webkit-scrollbar {
  width: 2px;
  margin-bottom: 450px;
  display: none;
}

.dashboard-activities-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #16deb566;
  margin-bottom: 450px;
}

.dashboard-activities-wrapper::-webkit-scrollbar-thumb {
  background-color: #16deb5;
  outline: 1px solid #16deb5;
  margin-bottom: 450px;
}



/* ----------loader----------- */

.box {
  font-size: 30px;
}

button {
  color: var(--loader);
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: calc(16px + 2vw);
}

.loader-15 {
  background: var(--loader);
  position: relative;
  animation: loader-15 0.8s ease-in-out infinite;
  animation-delay: 0.4s;
  width: 0.25em;
  height: 0.8em;
  margin: 0 0.5em;
  border-radius: 20px;
}

.loader-15:after,
.loader-15:before {
  content: "";
  position: absolute;
  width: 0.25em;
  height: 1.2em;
  background: inherit;
  animation: inherit;
  bottom: 0;
}

.loader-15:before {
  right: 0.6em;
  animation-delay: 0.2s;
  border-radius: 5px;
}

.loader-15:after {
  left: 0.6em;
  animation-delay: 0.6s;
  border-radius: 5px;
}

@keyframes loader-15 {
  0%,
  100% {
    box-shadow: 0 0 0 var(--loader), 0 0 0 var(--loader);
  }

  50% {
    box-shadow: 0 -0.25em 0 var(--loader), 0 0.25em 0 var(--loader);
    background-color: var(--loader);
  }
}

.success-wrapper .copy-icon {
  width: 4%;
  margin: 0 auto !important;
}



/* -------Home screen Popup------- */
.homeBg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000e0;
  top: 0;
}

.homeAndroidBg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000e0;
  top: 0;
}

.homeScreenPopup {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 90%;
  margin: auto;
  padding: 30px 20px;
  border-radius: 10px;
  text-align: center;
  background-color: var(--primary);
}

.homeScreenPopup h4 {
  margin: 30px 0 20px;
}

.homeScreenPopup p {
  color: var(--quaternary);
  font-size: 16px;
  margin: 0;
}

.homeScreenPopup img {
  width: 60px;
}

.homeScreenPopup span {
  /* display: block; */
  font-weight: 550;
  color: var(--loader);
  font-size: 18px;
}

.androidHomeScreenPopup {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 90%;
  margin: auto;
  padding: 30px 20px;
  border-radius: 10px;
  text-align: center;
  background-color: var(--primary);
}

.androidHomeScreenPopup h4 {
  margin: 30px 0 20px;
}

.androidHomeScreenPopup p {
  color: var(--quaternary);
  font-size: 16px;
  margin: 0;
}

.androidHomeScreenPopup img {
  width: 60px;
}

.androidHomeScreenPopup span {
  /* display: block; */
  font-weight: 550;
  color: var(--loader);
  font-size: 18px;
}
